import React from 'react'
import BackgroundImage from 'gatsby-background-image'
import { graphql, StaticQuery } from 'gatsby'
import styled from '@emotion/styled'
import Button from '../quoteButton'

import years from '../../assets/badge1@3x.png'
import warranty from '../../assets/badge2@3x.png'
export default () => (
    <StaticQuery
        query={graphql`
                query {
                    background: file(relativePath: { eq: "home/quality@2x.jpg" }) {
                        childImageSharp {
                            fluid(quality: 70, maxWidth: 1600, maxHeight: 1024, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                        }
                    }
                    mobileBackground: file(relativePath: { eq: "home/quality-m@2x.jpg" }) {
                        childImageSharp {
                            fluid(quality: 70, maxWidth: 621, maxHeight: 1344, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                        }
                    }
                }
            `}
        render={data => {
            const aosA = {
                'data-aos': 'fade-in',
                'data-aos-delay': 500,
                'data-aos-duration': 1000,
            }
            const aosB = {
                'data-aos': 'fade-in',
                'data-aos-delay': 750,
                'data-aos-duration': 1000,
                'data-aos-anchor': '#quality-title'

            }

            const sources = [
                data.mobileBackground.childImageSharp.fluid,
                {
                    ...data.background.childImageSharp.fluid,
                    media: `(min-width: 769px)`,
                },
            ]
            return (
                <StyledBG Tag="div" fluid={sources} className="scroll-child" id="quality">
                    <div className="container">
                        <h2 className="gibson fw-600 fs-45 m-fs-30 s-fs-30" {...aosA} id="quality-title">Quality is our game</h2>
                        <p className="fs-20 gibson m-fs-16 s-fs-16"{...aosB}>And we win every time</p>
                        <div className="icons">
                            <div {...aosB}>
                                <img src={years} />
                            </div>
                            <div {...aosB}>
                                <img src={warranty} />
                            </div>
                        </div>
                        <div className="frosted" {...aosB}>
                            <div className="columns inner callOut">
                                <div className="column is-7 textColumn" {...aosB}>
                                    <p className="fs-16 m-fs-10 s-fs-16 fw-200">
                                        From our 86-point quality inspection, to our
                                        25 year workmanship and roof penetration
                                        warranties, quality is our game.
                                </p>
                                </div>
                                <div className="column buttonColumn" {...aosB}>
                                    <Button />
                                </div>
                            </div>
                        </div>
                    </div>
                </StyledBG >

            )
        }}
    />
)

const StyledBG = styled(BackgroundImage)`
    background: #000;
    &:before{
        background-size: cover !important;
    }
    
    .container{
        min-height: 100vh;
        @media screen and (max-width: 420px){
            padding-top: 29.951690821256039rem;
            padding-bottom: 12.077294685990338rem;
        
        }
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    p, h2{
        color: #fff;
    }
    .icons{
        display: flex;
         
        div{
            display: inline-block;
            padding: 40px 20px 85px;
            img{
                width: 135px;
                @media screen and (max-width: 1199px){
                    width: 11.197916666666667rem;
                }
                @media screen and (max-width: 420px){
                    width: 29.468599033816425rem;
                }

                
            }
        }
    }
    .callOut{
        max-width: 656px;
        padding: 23px 35px;
        border-radius: 4px;
        @media screen and (max-width: 1199px){
            max-width: 54.6875rem;
        }
        @media screen and (max-width: 420px){
            max-width: 84.29951690821256rem;
            padding: 8.21256038647343rem;
        }
        p{
            color: #222;
            line-height: 1.5625em;
        }
    }
    .column{
        padding: 0;
    }
    .textColumn{
        padding-right: 0;
    }
    .buttonColumn{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 0;

        @media screen and (max-width: 420px){
            padding-top: 30px;
            justify-content: center;
        }
    }
    
`

