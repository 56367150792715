import React from 'react'
import styled from '@emotion/styled'
import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image'
import { graphql, StaticQuery } from 'gatsby'
import Button from '../quoteButton'

export default () => (
    <StaticQuery
        query={graphql`
            query {
                background: file(relativePath: { eq: "homeExport/batteryBackground@2x.png" }) {
                    childImageSharp {
                        fluid(quality: 70, maxWidth: 1500, cropFocus: CENTER) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                    }
                }
                mobileBackground: file(relativePath: { eq: "homeExport/batteryBackgroundMobile@2x.png" }) {
                    childImageSharp {
                        fluid(quality: 70, maxWidth: 828, cropFocus: CENTER) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                    }
                }
                brand: file(relativePath: { eq: "homeExport/batteryLogo@3x.png" }) {
                    childImageSharp {
                        fixed(quality: 67, width: 131) {
                            ...GatsbyImageSharpFixed_withWebp
                        }
                    }
                }
                
            }
        `}
        render={data => {

            // Art direction!
            const sources = [
                data.mobileBackground.childImageSharp.fluid,
                {
                    ...data.background.childImageSharp.fluid,
                    media: `(min-width: 769px)`,
                },
            ]

            const aosA = {
                'data-aos': 'fade-in',
                'data-aos-delay': 500,
                'data-aos-duration': 1000,
            }
            const aosB = {
                'data-aos': 'fade-in',
                'data-aos-delay': 1000,
                'data-aos-duration': 1000,
                'data-aos-anchor': '#battery-title'
            }

            return (
                <BackgroundImage Tag="div" fluid={sources} className="hero scroll-child">
                    <StyledBattery className="container">
                        <div className="columns">
                            <div className="column brand is-8-desktop is-6-tablet"{...aosB} >
                                <Img fixed={data.brand.childImageSharp.fixed} className="brand-icon" />
                            </div>
                            <div className="column is-4-desktop is-6-tablet mobile-column">
                                <div>
                                    <h2 id="battery-title" className="gibson fw-600 fs-45 m-fs-30 s-fs-30 is-white" {...aosA}>
                                        Backup Battery
                                    </h2>
                                    <div className="mobile-brand" {...aosB}>
                                        <Img fixed={data.brand.childImageSharp.fixed} />
                                    </div>
                                </div>
                                <div className="frosted" {...aosB}>
                                    <div className="inner box">
                                        <p className="fs-14 m-fs-12 s-fs-16 fw-400">A battery system charges with the energy produced by your solar system,
                                        giving you access to power when you need it most, day or night.
                                        It even keeps your home essentials running in the event of a power outage or natural disaster,
                                        making sure you can focus on keeping your family safe and secure.
                                    </p>
                                        <div {...aosB}>
                                            <Button />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </StyledBattery>
                </BackgroundImage>
            )
        }}
    />
)

const StyledBattery = styled('div')`
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    
    .columns{
        justify-content: space-between;
        
        
        .brand{
            display: flex;
            align-items: flex-end;
            justify-content: flex-start;
            @media screen and (max-width:768px){
                display: none;
            }
        }
        h2{
            color: #fff;
            text-align: center;
            @media screen and (min-width:1200px){
                margin-top: 100px;
            }
            
        }
        .box{
            text-align: center;
            margin-top: 15px;
            padding: 45px;
            padding-bottom: 60px;
            p{
                line-height: 1.5em;
                padding-bottom: 60px;
                text-align: center;
                color: #707070;
                text-align: left;
            }

            @media screen and (max-width:1199px){
                padding: 3.90625rem;
                margin-top: 1.432291666666667rem;
                p{
                    padding-bottom: 5.859375rem;
                }
            }

            @media screen and (max-width: 420px){
                padding: 9.661835748792271rem;
                p{
                    padding-bottom: 10.265700483091787rem;
                }
            }
            @media screen and (min-width:1200px){
                margin-bottom: 100px;
            }
            
        }
        .mobile-brand{
            display: none;
            @media screen and (max-width:768px){
                display: flex;
                justify-content: center;
                margin-bottom: 100px;
            }
            @media screen and (max-width:420px){
                margin-bottom: 100px;
                margin-top: 30px;
                
            }
        }

        @media screen and (max-width:1199px){
            width: 79.4rem;
        }
        @media screen and (max-width:768px){
            display: flex;
            justify-content: center;
        }
        @media screen and (max-width: 420px){
            width: 83.1rem;
        }
    }
    .column{
        @media screen and (max-width:768px){
            max-width: 349px;
        }
    }
    .mobile-column{
        @media screen and (max-width:768px){
            min-height: 100vh;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-top: 24rem;
            padding-bottom: 9.903381642512077rem;
        }

    }
`
