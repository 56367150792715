import React, { useRef } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Product from './product'

import ppaGraph from '../../assets/graphs/ppa-graph.svg'
import loanGraph from '../../assets/graphs/loan-graph.svg'
import buyGraph from '../../assets/graphs/buy-graph.svg'

import 'swiper/css/swiper.css';
import Swiper from 'react-id-swiper';

export default () => {

    const ref = useRef(null);


    const goTo = (i) => {
        if (ref.current !== null && ref.current.swiper !== null)
            ref.current.swiper.slideTo(i);

    };

    const params = {
        preloadImages: false,
        effect: 'fade',
        allowTouchMove: false,
        speed: 1000

    };

    return (
        <StaticQuery
            query={graphql`
                query {
                    product0: file(relativePath: { eq: "homeExport/productPPA@2x.png" }) {
                        childImageSharp {
                            fluid(quality: 70, maxWidth: 1500, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                        }
                    }
                    product1: file(relativePath: { eq: "homeExport/productLoan@2x.png" }) {
                        childImageSharp {
                            fluid(quality: 70, maxWidth: 1500, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                        }
                    }
                    product2: file(relativePath: { eq: "homeExport/productCash@2x.png" }) {
                        childImageSharp {
                            fluid(quality: 70, maxWidth: 1500, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                        }
                    }
                }
            `}
            render={data => {
                return (
                    <div className={`slider scroll-child`} id="options">
                        <Swiper {...params} ref={ref}>
                            <div className="in-active">
                                <Product
                                    active={0}
                                    fluid={data.product1.childImageSharp.fluid}
                                    pros={[
                                        '$0 Upfront',
                                        'Significantly lower payments',
                                        'No payment increase',
                                        'You keep Tax Benefits & Rebates',
                                        'Company maintains everything'
                                    ]}
                                    cons={[
                                        'Interest',
                                        'Increase DTI',
                                    ]}
                                    customs={{
                                        money: `Doesn't want to tie up cash`,
                                        tax: 'Large tax appetite',
                                        time: 'Has time to spare',
                                        maintenance: 'Freedom to move and still be ahead'
                                    }}
                                    graph={loanGraph}
                                    onClickFn={(newIndex) => { goTo(newIndex) }}
                                    howItWorks={`We install the system at no upfront cost to you. 
                        Your energy bill goes away and you redirect the money you were paying to your utility company into the ownership of your system at a *lower payment than you were making before.`}
                                    terms={'* Your payment will vary depending on the market and what program you qualify for.'}
                                />
                            </div>
                            <div className="in-active">
                                <Product
                                    active={1}
                                    fluid={data.product2.childImageSharp.fluid}
                                    pros={[
                                        'No interest',
                                        'Fast pay off',
                                        'Best savings',
                                        'Company maintains everything'
                                    ]}
                                    cons={[
                                        'Large upfront investment',
                                        'Ties up cash for 5-7 years',
                                    ]}
                                    customs={{
                                        money: `Extra cash on hand`,
                                        tax: 'Large tax appetite',
                                        time: 'Quickest payoff possible',
                                        maintenance: 'Plans to live in house for a long time'
                                    }}
                                    graph={buyGraph}
                                    onClickFn={(newIndex) => { goTo(newIndex) }}
                                    howItWorks={`You pay for the entire cost of the system upfront at the time of installation, eliminating your electricity bill and any 
                        type of solar payment. 
                        The typical payback period ranges from 5-7 years depending on regional and system specific details.`}
                                />
                            </div>
                            <div className="in-active">
                                <Product
                                    active={2}
                                    fluid={data.product0.childImageSharp.fluid}
                                    pros={[
                                        '$0 Upfront',
                                        'Lower Rate',
                                        'Only pay for power',
                                        'Company maintains everything'
                                    ]}
                                    cons={[
                                        'Rate goes up yearly',
                                        'Minimum savings',
                                        'Tax benefits & rebates go to company'
                                    ]}
                                    customs={{
                                        money: `Doesn't want to tie up cash`,
                                        tax: 'Pays too little in taxes',
                                        time: 'Wants savings now',
                                        maintenance: 'Low maintenance risk'
                                    }}
                                    graph={ppaGraph}
                                    onClickFn={(newIndex) => { goTo(newIndex) }}
                                    howItWorks={`With a PPA, you pay for the energy your system produces at a lower rate than your utility provider.
                        Your yearly rate increase is locked in at a maximum of 2.9 % per year, and the solar company owns and maintains the system.`}
                                />
                            </div>
                        </Swiper >
                    </div >
                )
            }}
        />
    )
} 