import React from 'react'
import { graphql, StaticQuery, Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import styled from '@emotion/styled'
import designIcon from '../../assets/button-design-icon.svg'
import learnMoreIcon from '../../assets/learn-more-icon.svg'
import Div100vh from 'react-div-100vh'
import * as Scroll from 'react-scroll';
export default () => (



    <StaticQuery
        query={graphql`
                query {
                    desktop: file(relativePath: { eq: "homeExport/hero@2x.png" }) {
                        childImageSharp {
                            fluid(quality: 70, maxWidth: 2000, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                        }
                    }
                }
                `}
        render={data => {
            const imageData = data.desktop.childImageSharp.fluid
            const aosA = {
                'data-aos': 'fade-in',
                'data-aos-delay': 500,
                'data-aos-duration': 1000
            }
            const aosB = {
                'data-aos': 'fade-in',
                'data-aos-delay': 1000,
                'data-aos-duration': 1000,
                'data-aos-anchor': '#home-title'
            }

            return (
                <StyledHero id="home" className="scroll-child">
                    <BackgroundImage Tag="section" fluid={imageData} className="hero is-fullheight">
                        <Div100vh className="hero-body">
                            <div className="container has-text-centered">
                                <h1 className="gibson fw-600 fs-45 m-fs-37" id="home-title" style={{ textTransform: 'uppercase', letterSpacing: '0.025em' }} {...aosA}>A Better Way</h1>
                                <p className="gibson fs-30 fw-600 m-fs-24" {...aosB}>
                                    to power your life
                                </p>
                                <Link className="button is-white is-uppercase ls-50" to="/savings-calculator" {...aosB}>
                                    <span className="custom-icon">
                                        <img src={designIcon} />
                                    </span>
                                    Design your system
                                </Link>
                            </div>
                            <div  {...aosB} className='learn-more-container'>
                                <Scroll.Link className="learn-more" to="options" smooth={true} duration={1000} >
                                    <p className="fw-100 gibson fs-14 is-uppercase has-text-white ls-50">Learn More</p>
                                    <img src={learnMoreIcon} />
                                </Scroll.Link>
                            </div>
                        </Div100vh>

                    </BackgroundImage>
                </StyledHero>
            )
        }}
    />
)

const StyledHero = styled(Div100vh)`
    
    .hero{
        background: #000;
    }
    .hero-body{
        color: #fff;
        background: linear-gradient(180deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.1) 10%);
        @media screen and (max-width: 420px){
            background: linear-gradient(180deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.1) 10%),
            linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.1) 30%);
        }
        
        
            .container{
                @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
                    margin-top: -200px;
                }
                @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
                    margin-top: -150px;
                }
            }
        }
        
    }
    .learn-more-container{
        position: absolute;
        bottom: 40px;
        left: 50%;

    }
    .learn-more{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 200px;
        position: absolute;
        bottom: 40px;
        left: 50%;
        margin-left: -100px;
        img{
            margin-top: 16px;
            width: 25px;
        }


        @media screen and (max-width: 420px){
            img{
                width: 6.038647343rem;
            }

        }
    }
    .button{
        margin-top: 35px;

        padding: 21px 24px;
        @media screen and (max-width: 1199px){
            padding: 1.822916666666667rem 3.125rem;
            margin-top: 4.557291666666667rem;

        }

        @media screen and (max-width: 420px){
            padding: 3.623188406rem	5.797101449rem;
            margin-top: 8.45410628rem;
            font-size: 2.657004831rem;
        }
        .custom-icon{
            width: 12px;
            margin-right: 7px;
            @media screen and (max-width: 420px){
                width: 2.898550725rem;
                margin-right: 1.5rem;
            }
        }
        img{
            width: 12px;
            @media screen and (max-width: 420px){
                width: 2.898550725rem;
                margin-top: 4px;
                
            }
        }
    }
    h1, .fs-20{
        @media screen and (max-width: 1199px){
            width: 40rem;
            margin-left: auto;
            margin-right: auto;
        }
        @media screen and (max-width: 420px){
            width:100%;
        }
        
    }
    .fs-20{
        @media screen and (max-width: 1199px){
            width: 30rem;
            margin-top: 2.604166666666667rem;
        }
        @media screen and (max-width: 420px){
            width:70%;
            margin-top: 4.830917874rem;
        }
    }
` 
