import React, { useState, useRef } from 'react'

import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image';

import 'swiper/css/swiper.css';
import Swiper from 'react-id-swiper';
import styled from '@emotion/styled'
import arrowLeft from '../../assets/arrowLeft.svg'
import arrowRight from '../../assets/arrowRight.svg'
import reviewStar from '../../assets/reviewStar.svg'
import Button from '../quoteButton'

export default () => {
    const [slideIndex, setIndex] = useState(0);
    const ref = useRef(null);

    const params = {
        preloadImages: false,
        centeredSlides: true,
        effect: 'fade',

        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        shouldSwiperUpdate: true,
        loop: false,
        on: {
            slideNextTransitionStart: () => {
                if (ref.current !== null && ref.current.swiper !== null)
                    setIndex(ref.current.swiper.activeIndex)
            },
            slidePrevTransitionStart: () => {
                if (ref.current !== null && ref.current.swiper !== null)
                    setIndex(ref.current.swiper.activeIndex)
            },
        }
    };

    return (
        <StaticQuery
            query={graphql`
                query {
                    review0: file(relativePath: { eq: "home/reviews/utah.jpg" }) {
                        childImageSharp {
                            fluid(quality: 70, maxWidth: 1500, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                        }
                    }
                    review1: file(relativePath: { eq: "home/reviews/purSolar.jpg" }) {
                        childImageSharp {
                            fluid(quality: 70, maxWidth: 1500, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                        }
                    }
                    review2: file(relativePath: { eq: "home/reviews/sunState.jpg" }) {
                        childImageSharp {
                            fluid(quality: 70, maxWidth: 1500, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                        }
                    }
                    review3: file(relativePath: { eq: "home/reviews/solarPro.jpg" }) {
                        childImageSharp {
                            fluid(quality: 70, maxWidth: 1500, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                        }
                    }
                    review4: file(relativePath: { eq: "home/reviews/delSol.jpg" }) {
                        childImageSharp {
                            fluid(quality: 70, maxWidth: 1500, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                        }
                    }

                    review0_small: file(relativePath: { eq: "home/reviews/utah.jpg" }) {
                        childImageSharp {
                            fluid(quality: 70, maxWidth: 400, maxHeight: 400, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                    review1_small: file(relativePath: { eq: "home/reviews/purSolar.jpg" }) {
                        childImageSharp {
                            fluid(quality: 70, maxWidth: 400, maxHeight: 400, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                    review2_small: file(relativePath: { eq: "home/reviews/sunState.jpg" }) {
                        childImageSharp {
                            fluid(quality: 70, maxWidth: 400, maxHeight: 400, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                    review3_small: file(relativePath: { eq: "home/reviews/solarPro.jpg" }) {
                        childImageSharp {
                            fluid(quality: 70, maxWidth: 400, maxHeight: 400, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                    review4_small: file(relativePath: { eq: "home/reviews/delSol.jpg" }) {
                        childImageSharp {
                            fluid(quality: 70, maxWidth: 400, maxHeight: 400, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            `}


            render={data => {

                const reviews = [
                    {
                        id: 0,
                        number_of_stars: 5,
                        name: 'R. T.',
                        location: 'Alpine, UT',
                        content: `“The timing was impeccable! Just finished talking about solar with my mom and sister and they knocked on my door the same evening! 
                        Made the whole process to go solar seamless. Everything happened so much faster than expected and quicker than they were even expecting to happen!"`,
                        background: data.review0.childImageSharp.fluid,
                        avatar: data.review0_small.childImageSharp.fluid
                    },
                    {
                        id: 1,
                        number_of_stars: 4,
                        name: 'Ryan',
                        location: 'North Las Vegas, NV',
                        content: `"Kota was very helpful with the solar installation process. Their prices were better than the other solar companies I had met with and also had the best loan rates. 
                        My rep James was very knowledgeable with every question I had. Going with Kota for solar was a no brainer."`,
                        background: data.review1.childImageSharp.fluid,
                        avatar: data.review1_small.childImageSharp.fluid
                    },
                    {
                        id: 2,
                        number_of_stars: 5,
                        name: 'Stephen',
                        location: 'North Las Vegas, NV',
                        content: `"I met with and listened to a lot of solar companies before I decided to go with KOTA. They were hands down the best I found. 
                        Jay answered all my questions and he made it feel like he was working for me and not the company. I never felt left out of the loop of what was going on."`,
                        background: data.review2.childImageSharp.fluid,
                        avatar: data.review2_small.childImageSharp.fluid
                    },
                    {
                        id: 3,
                        number_of_stars: 5,
                        name: 'Kirsten',
                        location: 'Bay Area, CA',
                        content: `"We just had a new roof and solar panels installed. Pablo from Kota Energy Group knocked on our door. It's something we had been thinking about doing and we decided to do it. Pablo was personable, responsible, and efficient. 
                        His crew showed up when they said they would and did the work in a timely manner. We would highly recommend Pablo and Kota Energy Group!"`,
                        background: data.review3.childImageSharp.fluid,
                        avatar: data.review3_small.childImageSharp.fluid
                    },
                    {
                        id: 4,
                        number_of_stars: 5,
                        name: 'Mike',
                        location: 'Camarillo, CA',
                        content: `"James and Mike provided thorough, informative, uncomplicated and hands on service. During the project they were extremely responsive and helpful working thru any issues!"`,
                        background: data.review4.childImageSharp.fluid,
                        avatar: data.review4_small.childImageSharp.fluid
                    },
                ]

                const aosA = {
                    'data-aos': 'fade-in',
                    'data-aos-delay': 500,
                    'data-aos-duration': 1000,
                }
                const aosB = {
                    'data-aos': 'fade-in',
                    'data-aos-delay': 1000,
                    'data-aos-duration': 1000,
                    'data-aos-anchor': '#reviews-title'
                }

                return (
                    <Review id="reviews" className="scroll-child">
                        {reviews.map((r, i) => (
                            <Img key={'review-background-' + r.id} fluid={r.background} className={'hero is-fullheight ' + (r.id != slideIndex ? 'is-hidden' : '')} />
                        ))}

                        <div className="hero is-fullheight slider-container">
                            <HeroBody className="hero-body">
                                <div className="container">
                                    <div className="intro">
                                        <h1 id="reviews-title" className="gibson fw-600 fs-45 m-fs-30" {...aosA}>Reviews</h1>
                                        <p className="fs-20 m-fs-16 gibson" {...aosB}>KOTA's pride shining through</p>
                                    </div>
                                    <div className="columns" style={{ justifyContent: 'center' }}>
                                        <div className="column is-8">
                                            <div className={`slider`} {...aosB}>
                                                <Swiper {...params} ref={ref}>
                                                    {reviews.map((r, i) => (
                                                        <SliderContent key={'review-slide-' + i}>
                                                            <div className="box">
                                                                <article className="media">
                                                                    <figure className="media-left">
                                                                        <div className="image is-96x96">
                                                                            <Img fluid={r.avatar} style={{ width: '100%' }} />
                                                                        </div>
                                                                    </figure>
                                                                    <div className="media-content">
                                                                        <div className="our-content">
                                                                            {
                                                                                [...Array(r.number_of_stars)].map((_, j) => (
                                                                                    <img src={reviewStar} key={'star' + i + '_' + j} />
                                                                                ))
                                                                            }
                                                                            <p className="fs-17 roboto fw-200">{r.name}</p>
                                                                            <p className="fs-12 roboto fw-200 light lh-15">{r.location} </p>
                                                                        </div>
                                                                    </div>
                                                                </article>
                                                                <div className="description">
                                                                    <p className="roboto fs-12 lh-15 fw-100 s-fs-16">{r.content}</p>
                                                                </div>
                                                            </div>
                                                        </SliderContent>
                                                    ))}
                                                </Swiper>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'center' }} {...aosB}>
                                                <Button />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </HeroBody>
                        </div>
                    </Review>
                )
            }}
        />
    )
}

const Review = styled.div`
    .hero{
        background-color: #222;
        min-height: 100vh;
        &.slider-container{
            margin-top: -100vh;
            
        }
        &.gatsby-image-wrapper{
            
            & > div{
                padding-bottom: 0 !important;
            }
        }
    }
    .swiper-slide{
        width: 100%;
    }
    .slider{
        margin-bottom: 50px;
    }
`
const HeroBody = styled.div`
    padding-top: 15vh;
    @media screen and (max-width: 1199px){
        padding-top: 11.848958333333333rem;
        align-items: flex-start !important;
    }
    @media screen and (max-width: 768px){
        align-items: center !important;
    }
    @media screen and (max-width: 420px){
        padding-left: 0;
        padding-right: 0;
    }
    .intro{
        color: #fff;
        text-align: center;
        margin-bottom: 12vh;
        @media screen and (max-width: 1199px){
            margin-bottom: 9.114583333333333rem;
        }
        @media only screen and (min-device-width : 768px) and (max-device-width : 1024px){
            margin-bottom: 3rem;
        }
    }
    .swiper-button-next,
    .swiper-button-prev {
        
        width: 30px;
        height: 60px;
        margin-top: -80px;
        &.swiper-button-disabled {
            opacity: 1;
        }   
        &:after {
            display: block;
            content: ' ';
            background-size: 30px 60px;
            width: 30px;
            height: 60px;
        }        
    }
    .swiper-button-next {
        background-image: url(${arrowRight});
    }
    .swiper-button-prev {
        background-image: url(${arrowLeft});
    }

    .swiper-pagination-bullet{
        width: 12px;
        height: 12px;
        background: #fff;
        opacity: 0.3;
        
    }
    .swiper-pagination-bullet-active{
        background-color: #fff;
        opacity: 1;
    }
    .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet{
        margin: 0 12px;
    }
    
    @media screen and (max-width: 1199px){
        .container{
            width: 100%;
        }
        .swiper-button-next,
        .swiper-button-prev {
            display: none;
        }
        .swiper-pagination{
            z-index: 99;
        }
        .swiper-pagination-bullets{
            bottom: 30px;
            @media screen and (max-width: 420px){
                bottom: 20px;
            }
        }
        .swiper-pagination-bullet{
            background: #222;
            opacity: 0.1;
            &.swiper-pagination-bullet-active{
                opacity: 1;
            }
        }
    }
    
`
const SliderContent = styled.div`
    .box{
        width: 51%;
        padding: 40px 46px;
        margin: auto;
        margin-bottom: 70px;
        min-height: 220px;
        background: #fff;
        @media screen and (max-width: 1199px){
            width: 70%;
            margin-bottom: 0;
            padding-bottom: 60px;
            min-height: 240px;
        }
        @media screen and (max-width: 420px){
            width: auto;
            min-height: 300px;
            margin: 0 8.45410628rem;
            padding: 7.487922705rem	6.038647343rem;
        }
        .media-left{
            @media screen and (max-width: 420px){
                margin-right: 3.8647343rem;
            }
            .image{
                height: auto;
            }
        }
        .image{
            border-radius: 4px;
            overflow:hidden;
            @media screen and (max-width: 420px){
                width: 16.66666667rem;
            }
        }
        .description{
            margin-top: 20px;
        }
        .name{
            margin-top: 5px;
        }
        .light{
            margin-top: 3px;
            color: #777777;
        }
    }
`