import React from 'react'
import BackgroundImage from 'gatsby-background-image'
import styled from '@emotion/styled'

import proIcon from '../../assets/lists/pro-icon.svg'
import conIcon from '../../assets/lists/con-icon.svg'
import moneyIcon from '../../assets/lists/money-icon.svg'
import taxIcon from '../../assets/lists/tax-icon.svg'
import timeIcon from '../../assets/lists/time-icon.svg'
import maintenanceIcon from '../../assets/lists/maintenance-icon.svg'

import Button from '../quoteButton'

export default ({ fluid, onClickFn, active, pros, cons, customs, graph, howItWorks, terms = '' }) => {

    const aosA = {
        'data-aos': 'fade-in',
        'data-aos-delay': 500,
        'data-aos-duration': 1000
    }

    const aosB = {
        'data-aos': 'fade-in',
        'data-aos-delay': 750,
        'data-aos-duration': 1000,
        'data-aos-anchor': '.product-title'
    }

    return (

        <StyledSlide className="hero is-fullheight">
            <BackgroundImage Tag="div" fluid={fluid} className="hero-body">
                <h2 className="gibson fw-600 fs-45 product-title" style={{ color: '#fff' }} {...aosA}>
                    Save with solar, any way you choose it
            </h2>
            </BackgroundImage>
            <StyledFoot>
                <StyledNavTabs {...aosB}>
                    <div className="container">
                        <div className="columns">
                            <ul className="column is-11">
                                <li className={active == 0 ? "is-active" : ''}>
                                    <a onClick={() => { onClickFn(0) }}>
                                        <h2 className="fs-28 roboto fw-700 is-uppercase m-fs-28 s-fs-20">Loan</h2>
                                        <p className="roboto fw-400  fs-12">
                                            Enjoy the best of Solar with no money up-front
                                    </p>
                                    </a>
                                </li>
                                <li className={active == 1 ? "is-active" : ''}>
                                    <a onClick={() => { onClickFn(1) }}>
                                        <h2 className="fs-28 roboto fw-700 is-uppercase m-fs-28 s-fs-20">Cash / Buy</h2>
                                        <p className="roboto fw-400 fs-12">
                                            Say goodbye to bills
                                    </p>

                                    </a>
                                </li>
                                <li className={active == 2 ? "is-active" : ''}>
                                    <a onClick={() => onClickFn(2)}>
                                        <h2 className="fs-28 roboto fw-700 is-uppercase m-fs-28 s-fs-20">PPA</h2>
                                        <p className="roboto fw-400  fs-12">
                                            Pay less for electricity
                                        </p>

                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </StyledNavTabs>
                <div style={{ background: '#fff', paddingTop: '40px', paddingBottom: '40px' }}>
                    <div className="container" {...aosB}>
                        <div className="columns large-only">
                            <div className="column">
                                <p className="fw-500 gibson fs-15 mb">Who it's for</p>
                                <IconList className="icon-list ">
                                    <li className="money">{customs.money}</li>
                                    <li className="tax">{customs.tax}</li>
                                    <li className="time">{customs.time}</li>
                                    <li className="maintenance">{customs.maintenance}</li>
                                </IconList>
                            </div>
                            <div className="column">
                                <p className="fw-500 gibson fs-15 mb">Pros</p>
                                <IconList className="icon-list pros">
                                    {pros.map((p, i) => <li key={'list-' + i}>{p}</li>)}
                                </IconList>
                            </div>
                            <div className="column">
                                <p className="fw-500 gibson fs-15 mb">Cons</p>
                                <IconList className="icon-list cons">
                                    {cons.map((c, i) => <li key={'list-' + i}>{c}</li>)}
                                </IconList>
                            </div>


                            <div className="column">
                                <div className="graph">
                                    <p className="fw-500 gibson fs-15">Cost / Savings</p>
                                    <img src={graph} />
                                </div>
                            </div>
                        </div>
                        <div className="columns large-only">
                            <div className="column is-three-quarters">
                                <p className="fw-500 gibson fs-15 mb">How it works</p>
                                <p className="fw-200 roboto fs-13 lh-15">{howItWorks}</p>
                                {
                                    terms && (
                                        <p className="fw-200 roboto fs-9" style={{marginTop: '1em'}}>{terms}</p>
                                    )
                                }
                            </div>
                            <div className="column is-quarter" style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'flex-end'
                            }}>
                                <Button />
                            </div>
                        </div>
                    </div>
                    <div className="columns small-only is-mobile is-multiline">
                        <div className="column is-half">
                            <p className="fw-500 gibson fs-15 m-fs-20 mb">Who it's for</p>
                            <IconList className="icon-list ">
                                <li className="m-fs-16 fw-400 money">{customs.money}</li>
                                <li className="m-fs-16 fw-400 tax">{customs.tax}</li>
                                <li className="m-fs-16 fw-400 time">{customs.time}</li>
                                <li className="m-fs-16 fw-400  maintenance">{customs.maintenance}</li>
                            </IconList>
                        </div>
                        <div className="column is-half">
                            <div className="graph">
                                <p className="fw-500 gibson m-fs-20 fs-15">Cost / Savings</p>
                                <img src={graph} />
                            </div>
                        </div>
                        <div className="column is-full">
                            <p className="fw-500 gibson fs-15 m-fs-20 mb">How it works</p>
                            <p className="fw-200 roboto fs-14 lh-15 s-fs-16">{howItWorks}</p>
                            {
                                    terms && (
                                        <p className="fw-200 roboto fs-9 m-fs-9 s-fs-11" style={{marginTop: '1em'}}>{terms}</p>
                                    )
                                }
                        </div>

                        <div className="column is-half pro-con">
                            <p className="fw-500 gibson fs-15 m-fs-20 mb">Pros</p>
                            <IconList className="icon-list pros">
                                {pros.map((p, i) => <li key={'list-' + i} className="m-fs-16 fs-400">{p}</li>)}
                            </IconList>
                        </div>
                        <div className="column is-half pro-con">
                            <p className="fw-500 gibson fs-15 m-fs-20 mb m-rtl s-ltr">Cons</p>
                            <IconList className="icon-list cons">
                                {cons.map((c, i) => <li key={'list-' + i}>{c}</li>)}
                            </IconList>
                        </div>
                        <div className="column is-full" style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button />
                        </div>

                    </div>
                </div>
            </StyledFoot>
        </StyledSlide >


    )
}

const StyledSlide = styled('div')`
    background: #000;
    .hero-body{
        min-height: 30vh;
        @media screen and (max-width: 1199px){
            min-height: 50vh;
            h2{
                display: none;
            }
        }
        @media screen and (max-width: 959px){
            min-height: 30vh;
        }
        align-items: center;
        justify-content: center;
    }
`

const StyledFoot = styled('div')`
    .mb{
        margin-bottom: 18px;
    }
    .graph{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        @media screen and (min-width: 1200px){
            min-height: 272px;
        }
    }


    /* Remember to make this responsive */
    & > div{
        @media screen and (min-width: 1200px){
            min-height: 305px;
        }
    }
    .large-only{
        @media screen and (max-width: 1199px){
            display: none;
        }
    }
    .small-only{
        padding: 2.604166666666667rem 7.8125rem;
        flex-wrap: wrap;
        @media screen and (min-width: 1200px){
            display: none;
        }
        @media screen and (max-width: 420px){
            padding: 20px 30px;

        }
        .column.is-half{
            flex: 1;
            @media screen and (max-width: 420px){
                width: 100%;
                flex: none;
                &.pro-con{
                    width: 50%;
                    flex: 1;
                    margin-bottom: 0;
                }
                
            }
            
        }
        .graph{
            img{
                width: 100%;
            }
            
        }
        .is-full{
            padding-top: 60px;
            padding-bottom: 60px;
            @media screen and (max-width: 420px){
                padding: 0.75rem;
            }
        }
        .column{
            @media screen and (max-width: 420px){
                margin-bottom: 17.87439614rem;
            }
        }

    }
    
`

const StyledNavTabs = styled('nav')`
    margin-top: -129px;
    @media screen and (max-width: 1199px){
        margin-top: -113px;
    }

    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
        margin-top: -115px;
    }
    
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
        margin-top: -12.4rem;
    }

    @media screen and (max-width: 420px){
        margin-top: -14.276570048rem;
    }
    

    &:not(:last-child){
        margin-bottom: 0;
    }
    .columns{
        @media screen and (max-width: 420px){
            margin-left: 0;
            margin-right: 0;
        }
    }
    .is-11{
        @media screen and (max-width: 1199px){
            width: 80%;
        }

        @media screen and (max-width: 768px){
            padding-top: 0; 
            padding-bottom: 0;
        }

        @media screen and (max-width: 420px){
            width: calc(100%);
            padding-left: 0px; 
            padding-right: 0px;
            padding-bottom: 0;
        }
    }
    
    ul{
        display: flex;
        margin: 0 auto;
    }
    li{
        width: 33.33%;
        
        text-align: center;
        a{  
            display: inline-block;
            width: 100%;
            padding-top: 30px;
            padding-bottom: 30px;
            @media screen and (max-width: 768px){
                padding-top: 2.473958333333333rem;
                padding-bottom: 2.473958333333333rem;
            }
            @media screen and (max-width: 420px){
                padding-top: 4.347826087rem;
                padding-bottom: 4.347826087rem;
            }
            flex-direction: column;
            border-radius: 4px 4px 0 0;
            & > *{
                color: #fff;
            }
        } 
        &.is-active{
            
            a{
                background: #fff;
                & > *{
                    color: #000;
                }
            }
        }
    }
    p{
        margin-top: 8px;
        @media screen and (max-width: 1199px){
            display: none;
        }
    }
    
`

const IconList = styled('ul')`
    li{
        &:before{
            content: '';
            width: 20px;
            height: 25px;
            position: absolute;
            margin-left: -33px;
            background-repeat: no-repeat;
            background-size: 20px auto;
            @media screen and (max-width: 420px){
                margin-top: 5px;
            }
            
        }
        padding-left: 33px;
        margin-bottom: 20px;
    }
    &.cons{
        li{
            &:before{
                background-image: url(${conIcon});
                @media screen and (max-width: 420px){
                    margin-top: 0;
                }
            }
            @media screen and (max-width: 420px){
                font-size: 2.898550725rem;
            }
        }
        @media screen and (max-width: 1199px){
            direction: rtl;
            li{
                padding-left: 0;
                padding-right: 33px;
                &:before{
                    margin-left: 0;
                    margin-right: -33px;
                }
            }
            
        }
    }
    &.pros{
        li{
            &:before{
                background-image: url(${proIcon});
                @media screen and (max-width: 420px){
                    margin-top: 0;
                }
            }
        }
    }

    .money{
        &:before{
            background-image: url(${moneyIcon});
            
        }
    }
    .tax{
        &:before{
            background-image: url(${taxIcon});
            margin-top: -4px
        }
    }
    .time{
        &:before{
            background-image: url(${timeIcon});
            margin-top: -4px
        }
    }
    .maintenance{
        &:before{
            background-image: url(${maintenanceIcon});
        }
    }

`