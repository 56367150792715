import React, { useEffect } from 'react';
import Layout from '../components/layout';

import Products from '../components/home/products'
import Hero from '../components/home/hero'

import Quality from '../components/home/quality'
import Equip from '../components/home/equip'
import Battery from '../components/home/battery'

import Reviews from '../components/home/reviews'
import "aos/dist/aos.css";
export default () => {
    let AOS;

    useEffect(() => {
        // Server-side rendering does not provide the 'document' object
        // therefore this import is required either in useEffect as exclusively executed on a client
        const AOS = require("aos");
        AOS.init({
            once: true,
        });
    }, []);

    useEffect(() => {
        if (AOS) {
            AOS.refresh();
        }
    });

    return (
        <Layout hasScroll={true}>
            <Hero />
            <Products />
            <Quality />
            <div id="equipment">
                <Equip />
                <Battery />
            </div>
            <Reviews />
        </Layout>
    )

}
