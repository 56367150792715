import React from "react";
import BackgroundImage from "gatsby-background-image";
import { graphql, StaticQuery } from "gatsby";
import styled from "@emotion/styled";
import Button from "../quoteButton";

import panelBrand from "../../assets/panelDesktop-brandLogo.png";
export default () => {
  const aosA = {
    "data-aos": "fade-in",
    "data-aos-delay": 500,
    "data-aos-duration": 1000,
  };
  const aosB = {
    "data-aos": "fade-in",
    "data-aos-delay": 1000,
    "data-aos-duration": 1000,
    "data-aos-anchor": "#equip-title",
  };

  const lists = [
    {
      title: "Higher Outputs",
      list: ["Better economics", "Superior shading performance"],
    },
    {
      title: "More Attractive",
      list: ["Pure Black design", "No visible circuitry", "Better curb appeal"],
    },
    {
      title: "Better Quality",
      list: [
        "Fewer failure points",
        "Industry leading 25-year warranty",
        "on parts, power, and labor.",
      ],
    },
  ];
  return (
    <StaticQuery
      query={graphql`
        query {
          desktop: file(
            relativePath: { eq: "homeExport/panelDesktop-2022.jpg" }
          ) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1280, maxHeight: 926) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          mobile: file(
            relativePath: { eq: "homeExport/panelMobile-2022.png" }
          ) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1256, maxHeight: 910) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      `}
      render={(data) => {
        return (
          <StyledEquip className="scroll-child">
            <div className="container">
              <h2
                className="gibson fw-600 fs-45 m-fs-30 s-fs-30"
                id="equip-title"
                {...aosA}
              >
                Only the best <br className="special-break" />
                equipment
              </h2>
              <div className="content-container mobile-hide" {...aosB}>
                <BackgroundImage
                  Tag="div"
                  fluid={data.desktop.childImageSharp.fluid}
                  className="background-image"
                >
                  <BrandImage src={panelBrand} />
                  <div className="description" {...aosB}>
                    <p className="fs-16 m-fs-10 fw-200 roboto">
                      Never skimp on home improvement. Based in the USA, our
                      panels produce 20% more energy per square meter than
                      traditional solar panels. And they look really good while
                      doing it.
                    </p>
                  </div>
                </BackgroundImage>
                <div className="list-container">
                  {lists.map((e) => (
                    <div {...aosB}>
                      <h3 className="fs-12 m-fs-7 fw-500 is-uppercase">
                        {e.title}
                      </h3>
                      <ul>
                        {e.list.map((l) => (
                          <li className="fs-12 m-fs-7 fw-200 m-fs-7">{l}</li>
                        ))}
                      </ul>
                    </div>
                  ))}
                  <div {...aosB}>
                    <Button />
                  </div>
                </div>
              </div>

              <div className="mobile-show">
                <BackgroundImage
                  Tag="div"
                  fluid={data.mobile.childImageSharp.fluid}
                  className="mobile-image"
                >
                  {
                    <div className="list-container">
                      <h3 className="s-fs-10 fw-500 is-uppercase">
                        {lists[0].title}
                      </h3>
                      <ul>
                        {lists[0].list.map((l) => (
                          <li className="s-fs-10 fw-200">{l}</li>
                        ))}
                      </ul>
                    </div>
                  }
                  <BrandImage src={panelBrand} />
                </BackgroundImage>
              </div>
              <div className="mobile-show">
                <div className="list-container">
                  <div className="half">
                    <h3 className="s-fs-10 fw-500 is-uppercase">
                      {lists[1].title}
                    </h3>
                    <ul>
                      {lists[1].list.map((l) => (
                        <li className="s-fs-10 fw-200">{l}</li>
                      ))}
                    </ul>
                  </div>
                  <div className="half">
                    <h3 className="s-fs-10 fw-500 is-uppercase">
                      {lists[2].title}
                    </h3>
                    <ul>
                      {lists[2].list.map((l) => (
                        <li className="s-fs-10 fw-200">{l}</li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="description">
                  <p className="fs-16 m-fs-10 s-fs-16 fw-200 roboto">
                    Never skimp on home improvement. Based in the USA, our
                    panels produce 20% more energy per square meter than
                    traditional solar panels. And they look really good while
                    doing it.
                  </p>
                  <Button />
                </div>
              </div>
            </div>
          </StyledEquip>
        );
      }}
    />
  );
};

const StyledEquip = styled("div")`
  min-height: 100vh;
  .special-break {
    display: none;
  }
  @media screen and (max-width: 420px) {
    padding-top: 27.294685990338164rem;
    padding-bottom: 20.531400966183575rem;
    .special-break {
      display: inline;
    }
  }
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
  p,
  h2,
  h3,
  li {
    color: #fff;
  }
  h2 {
    text-align: center;
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .content-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    max-width: 860px;
    padding-top: 50px;
  }
  .background-image {
    width: 640px;
    height: 463px;
    position: relative;
    display: flex;
    align-items: flex-end;
    .description {
      width: 80%;
      margin-left: 3%;
      p {
        line-height: 1.6em;
      }
    }

    @media screen and (max-width: 1199px) {
      width: 53.255208333333333rem;
      height: 38.671875rem;
    }
  }

  .list-container {
    padding-top: 50px;
    h3 {
    }
    ul {
      margin-top: 7px;
      margin-bottom: 30px;
      li {
        position: relative;
        line-height: 1.8em;
        &:before {
          content: "";
          width: 5px;
          height: 5px;
          border-radius: 100%;
          border: 1px solid #707070;
          position: absolute;
          top: 50%;
          margin-left: -15px;
          margin-top: -2px;
        }
        padding-left: 15px;
      }
      &:last-of-type {
        padding-bottom: 30px;
      }
    }
  }

  .mobile-image {
    width: 100%;
    background-position: center top !important;
    height: 270px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .list-container {
      display: inline-block;
      width: 100%;
      ul {
        margin-bottom: 0;
      }
    }
    margin-bottom: 40px;
  }

  .mobile-show {
    width: 100%;
    .list-container {
      padding: 0 10rem;
      ul {
        &:last-of-type {
          padding-bottom: 0px;
        }
      }
    }
    .half {
      width: 45%;
      float: left;
      &:last-of-type {
        width: 55%;
      }
    }
    .description {
      padding: 20px 50px;
      padding-bottom: 0;
      p {
        text-align: center;
        margin-bottom: 50px;
      }
      text-align: center;
    }
  }
`;

const BrandImage = styled("img")`
  position: absolute;
  right: 15%;
  width: 15%;
  bottom: 30%;
  @media screen and (max-width: 420px) {
    right: 20%;
    width: 20%;
  }
`;
